// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import SearchDoctor from 'components/SearchDoctor';

// Context
import { InternacionalizationContext } from 'context/internacionalization';
import { HeaderContext } from 'context/header';

//Helper
import { getMarkdown, getFeaturedMarketingContent } from 'utils/getMarkdown';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }
`;

export const Part = styled.div`
  grid-column: ${props => props.gridColumn};
  display: ${props => props.isDisplay};
  overflow: ${props => props.isOverflow};
`;

const SearchDoctorContainer = styled.div`
  min-height: 28.75rem;
  margin-bottom: 4.375 rem;

  @media ${device.laptop} {
    min-height: unset;
    margin-bottom: 1.875rem;
  }
`;

const RenderPage = (page, isDesktop, location, language) => {
  const featuredMarketingContent = getFeaturedMarketingContent(
    page[0]?.featuredMarketing,
    language
  );

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'1 / -1'}>
          <SearchDoctorContainer>
            <SearchDoctor
              location={location}
              textLabels={getMarkdown(page, 'ckxrtfag0nkcz0d26v6tinihf')}
            />
          </SearchDoctorContainer>
        </Part>
      </GridContainer>
    </>
  );
};

const EncontreSeuMedico = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);
  const { language, setShowSelect } = useContext(InternacionalizationContext);

  const getImage = getMarkdown(page, 'ckmuw8keo1pdg0c80wverj9me', false, true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  useEffect(() => {
    setShowSelect(true);
    return () => setShowSelect(false);
  }, []);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop, location, language)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "ckmuvxofk1nwm0c80cdr5n4rf" }) {
                featuredMarketing(locales: [pt_BR, en, es]) {
                  id
                  type
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  breadcrumb
                  title
                  localizations(includeCurrent: true) {
                    locale
                    title
                    breadcrumb
                  }
                  fragments {
                    id
                    name
                    type
                    markdown
                    localizations(includeCurrent: true) {
                      locale
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                fragments(locales: [en, es, pt_BR]) {
                  id
                  name
                  localizations {
                    locale
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <EncontreSeuMedico
            page={response.gcms.site.pages}
            location={props.location}
            {...props}
          />
        );
      }}
    />
  );
};
