import flatten from 'lodash.flatten';

export const getMarkdown = (
  page,
  fragmentId,
  isMarkdown = false,
  assets = false,
  language
) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[
      findFragment.localizations.findIndex(item =>
        item.locale.toLowerCase().includes(language.toLowerCase())
      ) ?? 0
    ];

    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

export const getFeaturedMarketingContent = (rawContent, language) => {
  const formattedLanguage = language.toLowerCase();

  const formattedLocalizations =
    rawContent?.localizations?.[
      rawContent?.localizations?.findIndex(localizations =>
        localizations.locale.includes(formattedLanguage)
      )
    ] ?? [];

  const formattedFragments = rawContent?.fragments?.map(
    fragment =>
      fragment.localizations?.[
        fragment.localizations?.findIndex(localizations =>
          localizations.locale.includes(formattedLanguage)
        )
      ] ?? fragment
  );

  const translatedFeaturedMarketingContent = {
    ...rawContent,
    ...formattedLocalizations,
    ...formattedFragments,
  };

  return translatedFeaturedMarketingContent;
};
